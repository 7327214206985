import React from 'react'
import Explorer from './views/index'
import Explorer2 from './otherView/index'
import Explorer3 from './anotherView/index'

function getParameterByName (name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

function App (props) {
  console.log(props)
  const key = getParameterByName('key')
  const contractID = getParameterByName('contractID')
  const tokenID = getParameterByName('tokenID')
  const chainID = getParameterByName('chainID')

  if (key) {
    console.log(key)
    return <Explorer2 search={key}  />

  }
  else if (contractID && tokenID) {
    console.log(contractID,tokenID)
    return <Explorer3 contractID={contractID} tokenID={tokenID} chainID={chainID} />

  }
  else {
    const params = window.location.pathname.split('/')
    const id = params[2]
    const owner = params[3]
    return <Explorer id={id} owner={owner} />
  }
}

export default App
