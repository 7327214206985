import {
    ADD_STELLAR_URL
} from '../constants/ActionTypes'
import {
    niftronUserLambda, niftronTokenLambda,
    StellarUrl, StellarUrlTest, Version,
    NiftronIssuerPublicKey,
    NiftronDistributorPublicKey
} from "../../variables/constants"

const initialState = {
    niftronUserLambda, niftronTokenLambda,
    StellarUrl, StellarUrlTest, Version,
    NiftronIssuerPublicKey,
    NiftronDistributorPublicKey
}

export default function variables(state = initialState, action) {
    switch (action.type) {
        case ADD_STELLAR_URL:
            state.StellarUrl = action.text
            return state
        default:
            return state
    }
}
