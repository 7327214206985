import React, { useState, useEffect } from 'react'
import { CardMedia } from '@material-ui/core'
import DateRange from '@material-ui/icons/DateRange'
import { format } from 'timeago.js'

// core ../../components
import GridItem from '../../components/Grid/GridItem.js'
import GridContainer from '../../components/Grid/GridContainer.js'
import Card from '../../components/Card/Card.js'
import CardToken from '../../components/Card/CardToken.js'
import CardBody from '../../components/Card/CardBody.js'
import CardFooter from '../../components/Card/CardFooter.js'
import Button from '../../components/CustomButtons/Button'

import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import axios from 'axios'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import People from '@material-ui/icons/People'

import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import WorkIcon from '@material-ui/icons/Work'
import BeachAccessIcon from '@material-ui/icons/BeachAccess'
import Divider from '@material-ui/core/Divider'

import { green, red } from '@material-ui/core/colors'
import CircularProgress from '@material-ui/core/CircularProgress'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import image from '../../assets/img/Verify.png'
import styles from '../../assets/jss/material-kit-react/views/loginPage.js'

import { getTransferByNidAndReceiver } from '../../services/TransferManagement'
import {
  Server,
  Keypair,
  Transaction,
  Networks,
  AccountResponse
} from 'stellar-sdk'
const useStyles1 = makeStyles(styles)

const useStyles = makeStyles(theme => ({
  root2: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  root: {
    backgroundColor: theme.palette.background.paper
    // width: 500,
  },
  paperName: {
    padding: theme.spacing(1)
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
  paperValue: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  item: {
    minWidth: 360
  }
}))
function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

export default function Certificate (props) {
  const classes = useStyles()
  const classes1 = useStyles1()

  const theme = useTheme()
  const [value, setValue] = React.useState(0)
  const [transfer, setTransfer] = React.useState(null)
  const [showVerification, setShowVerification] = React.useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)

  const [loading, setLoading] = useState(true)
  const [nameLoading, setNameLoading] = useState(false)
  const [checkNameError, setCheckNameError] = useState(false)
  const [checkNameSuccess, setCheckNameSuccess] = useState(false)

  const [ipfsData, setIpfsData] = useState(null)
  const [ipfsDataArray, setIpfsDataArray] = useState([])

  const [ipfsTransferData, setIpfsTransferData] = useState(null)
  const [ipfsTransferDataArray, setIpfsTransferDataArray] = useState([])

  document.body.style.overflow = 'scroll'

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  useEffect(() => {
    async function fetchIpfs () {
      try {
        const res = await axios.get(
          `https://ipfs.infura.io/ipfs/${props.item.ipfsHash}`
        )
        if (res) {
          // //console.log(res.data)
          if (Object.keys(res.data).length === 0) {
            setIpfsData('NA')
          } else {
            setIpfsData(JSON.stringify(res.data))
            var array = []

            for (var i in res.data) array.push({ key: i, value: res.data[i] })

            setIpfsDataArray(array)
          }
          // setIpfsData(res.data)
        }
      } catch (er) {
        //console.log(er);
      }
    }
    // const fetchData = async () => {
    //   let result = await getTransferByNidAndReceiver(
    //     props.item.assetCode,
    //     props.item.assetIssuer,
    //     props.owner
    //   );
    //   if (result != null) {
    //     if (result.data) {
    //       //console.log(result.data);
    //       setTransfer(result.data);
    //     }
    //     setShowVerification(true);
    //   }
    // };

    // fetchData();
    fetchIpfs()
  }, [])
  // useEffect(() => {
  //   async function fetchIpfs() {
  //     try {
  //       const res = await axios.get(
  //         `https://ipfs.io/ipfs/${transfer.ipfsHash}`
  //       );
  //       if (res) {
  //         // //console.log(res.data)
  //         if (Object.keys(res.data).length === 0) {
  //           setIpfsTransferData("NA");
  //         } else {
  //           setIpfsTransferData(JSON.stringify(res.data));
  //           var array = [];

  //           for (var i in res.data) array.push({ key: i, value: res.data[i] });

  //           setIpfsTransferDataArray(array);
  //         }
  //         // setIpfsData(res.data)
  //       }
  //     } catch (er) {
  //       //console.log(er);
  //     }
  //   }
  //   fetchIpfs();
  // }, [transfer]);
  const handleClose = () => {
    setOpenDialog(false)
  }
  const handleOpen = () => {
    setOpenDialog(true)
  }

  const makePresentable = text => {
    return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  }

  const makePkPresentable = text => {
    return `${text.substring(0, 10)} ...`
  }
  const prepareStellarTransactionLink = txnHash => {
    const values = btoa(JSON.stringify({ transaction: txnHash }))
    //console.log(values);
    const stellarLaboratory =
      'https://laboratory.stellar.org/#explorer?resource=operations&endpoint=for_transaction&'
    const network = props.network
    return `${stellarLaboratory}values=${values}&network=${network}`
  }
  const prepareStellarAccountLink = publicKey => {
    const values = btoa(JSON.stringify({ account_id: publicKey }))
    //console.log(values);
    const stellarLaboratory =
      'https://laboratory.stellar.org/#explorer?resource=accounts&endpoint=single&'
    const network = props.network
    return `${stellarLaboratory}values=${values}&network=${network}`
  }
  const prepareStellarXDRLink = xdr => {
    const values = encodeURIComponent(xdr)
    //console.log(values);
    const stellarLaboratory = 'https://laboratory.stellar.org/#xdr-viewer?'
    const network = props.network
    return `${stellarLaboratory}input=${values}&type=TransactionEnvelope&network=${network}`
  }
  const xdrToTxnHash = xdr => {
    const network = props.network
    const parsedTx = new Transaction(
      xdr,
      network == 'test' ? Networks.TESTNET : Networks.PUBLIC
    )
    const txnHash = parsedTx.hash().toString('hex')

    return txnHash
  }

  return (
    <>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12} md={8} lg={8}>
          <Card>
            <CardMedia
              style={{
                height: 500,
                maxHeight: 500,
                'background-size': 'contain'
              }}
              image={props.item.previewUrl}
            />
          </Card>
          <Grid container justify='center' spacing={2}>
            {/* <Grid item>
              <Button
                color={'info'}
                width='50'
                // disabled={!showVerification}
                target='_blank'
                href={prepareStellarXDRLink(props.item.xdr)}
              >
                View in Blockchain
              </Button>
            </Grid>  */}
            <Grid item>
                <Button
                  color={'info'}
                  width='50'
                  // disabled={!showVerification}
                  target='_blank'
                  href={prepareStellarTransactionLink(xdrToTxnHash(props.item.xdr))}
                >
                  Verify in Blockchain
                </Button>
              </Grid>
          </Grid>
         
            {transfer && (
            <Dialog
              open={openDialog}
              onClose={handleClose}
              aria-labelledby='responsive-dialog-title'
              scroll={'body'}
            >
              <DialogTitle id='responsive-dialog-title'>
                {'Verification'}
              </DialogTitle>
              <DialogContent dividers>
                <List className={classes.root}>
                  <ListItem className={classes.item}>
                    <ListItemAvatar>
                      {nameLoading && (
                        <CircularProgress size={24} thickness={4} />
                      )}
                      {!nameLoading && checkNameSuccess && (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                      )}
                      {!nameLoading && checkNameError && (
                        <HighlightOffIcon style={{ color: red[500] }} />
                      )}
                    </ListItemAvatar>
                    {/* <ListItemText primary="Issued by" secondary={!nameLoading ? <> <img height={20}
                src={"https://i1.wp.com/www.iit.ac.lk/wp-content/uploads/2020/07/iit-favicon.png?fit=512%2C512&ssl=1"}></img>
                {" "}{makePresentable(transfer.senderAlias)} 
              </>
                : " ..."}
              /> */}
                    <Grid container spacing={2}>
                      <Grid
                        container
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        spacing={3}
                      >
                        <ListItemText primary='Issued by' />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        spacing={3}
                      >
                        <ListItemText
                          secondary={
                            !nameLoading ? (
                              <a
                                target={'_blank'}
                                href={prepareStellarAccountLink(
                                  transfer.assetIssuer
                                )}
                              >
                                {/* <img height={25}
                        src={"https://niftron-util.s3.amazonaws.com/CBM"}></img> */}
                                {/* {" "}{makePresentable(transfer.senderAlias)}  */}
                                {makePkPresentable(transfer.assetIssuer)}
                              </a>
                            ) : (
                              ' ...'
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider variant='inset' component='li' />
                  {transfer.signers.map((item, i) => (
                    <>
                      <ListItem className={classes.item}>
                        <ListItemAvatar>
                          {nameLoading && (
                            <CircularProgress size={24} thickness={4} />
                          )}
                          {!nameLoading && checkNameSuccess && (
                            <CheckCircleOutlineIcon
                              style={{ color: green[500] }}
                            />
                          )}
                          {!nameLoading && checkNameError && (
                            <HighlightOffIcon style={{ color: red[500] }} />
                          )}
                        </ListItemAvatar>
                        {/* <ListItemText primary="Issued by" secondary={!nameLoading ? <> <img height={20}
                src={"https://i1.wp.com/www.iit.ac.lk/wp-content/uploads/2020/07/iit-favicon.png?fit=512%2C512&ssl=1"}></img>
                {" "}{makePresentable(transfer.senderAlias)} 
              </>
                : " ..."}
              /> */}
                        <Grid container spacing={2}>
                          <Grid
                            container
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            spacing={3}
                          >
                            <ListItemText primary='Approved by' />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            spacing={3}
                          >
                            <ListItemText
                              secondary={
                                !nameLoading ? (
                                  <a
                                    target={'_blank'}
                                    href={prepareStellarAccountLink(
                                      item.publicKey
                                    )}
                                  >
                                    {makePkPresentable(item.publicKey)}

                                    {/* {" "}{makePresentable(transfer.senderAlias)}  */}
                                  </a>
                                ) : (
                                  ' ...'
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                      <Divider variant='inset' component='li' />
                    </>
                  ))}
                  <ListItem className={classes.item}>
                    <ListItemAvatar>
                      {nameLoading && (
                        <CircularProgress size={24} thickness={4} />
                      )}
                      {!nameLoading && checkNameSuccess && (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                      )}
                      {!nameLoading && checkNameError && (
                        <HighlightOffIcon style={{ color: red[500] }} />
                      )}
                      {/* <Avatar>
                  <BeachAccessIcon />
                </Avatar> */}
                    </ListItemAvatar>
                    {/* <ListItemText primary="Issued to" secondary={!nameLoading ? makePresentable(transfer.receiverAlias) : " ..."} /> */}
                    <Grid container spacing={2}>
                      <Grid
                        container
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        spacing={3}
                      >
                        <ListItemText primary='Issued to' />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        spacing={3}
                      >
                        <ListItemText
                          secondary={
                            !nameLoading ? (
                              <a
                                target={'_blank'}
                                href={prepareStellarAccountLink(
                                  transfer.receiver
                                )}
                              >
                                {makePkPresentable(transfer.receiver)}
                              </a>
                            ) : (
                              ' ...'
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider variant='inset' component='li' />

                  <ListItem className={classes.item}>
                    <ListItemAvatar>
                      {/* <CheckCircleOutlineIcon style={{ color: green[500] }} /> */}
                      {/* <Avatar> */}
                      {nameLoading && (
                        <CircularProgress size={24} thickness={4} />
                      )}
                      {!nameLoading && checkNameSuccess && (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                      )}
                      {!nameLoading && checkNameError && (
                        <HighlightOffIcon style={{ color: red[500] }} />
                      )}
                      {/* <ImageIcon /> */}
                      {/* </Avatar> */}
                    </ListItemAvatar>
                    {/* <ListItemText primary="Issued on" secondary={!nameLoading ? new Date(transfer.createdAt).toDateString() : " ..."} /> */}
                    <Grid container spacing={2}>
                      <Grid
                        container
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        spacing={3}
                      >
                        <ListItemText primary='Issued on' />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        spacing={3}
                      >
                        <ListItemText
                          secondary={
                            !nameLoading
                              ? new Date(transfer.createdAt).toDateString()
                              : ' ...'
                          }
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider variant='inset' component='li' />
                  <ListItem className={classes.item}>
                    <ListItemAvatar>
                      {nameLoading && (
                        <CircularProgress size={24} thickness={4} />
                      )}
                      {!nameLoading && checkNameSuccess && (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                      )}
                      {!nameLoading && checkNameError && (
                        <HighlightOffIcon style={{ color: red[500] }} />
                      )}
                      {/* <Avatar>
                  <BeachAccessIcon />
                </Avatar> */}
                    </ListItemAvatar>
                    {/* <ListItemText primary="Accepted on" secondary={!nameLoading ? new Date(transfer.updatedAt).toDateString() : " ..."} /> */}
                    <Grid container spacing={2}>
                      <Grid
                        container
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        spacing={3}
                      >
                        <ListItemText primary='Accepted on' />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        spacing={3}
                      >
                        <ListItemText
                          secondary={
                            !nameLoading
                              ? new Date(transfer.updatedAt).toDateString()
                              : ' ...'
                          }
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider variant='inset' component='li' />
                  {/* <ListItem className={classes.item}>
                  <ListItemAvatar>
                    {nameLoading && <CircularProgress
                      size={24}
                      thickness={4} />}
                    {!nameLoading && checkNameSuccess && <CheckCircleOutlineIcon style={{ color: green[500] }} />}
                    {!nameLoading && checkNameError && <HighlightOffIcon style={{ color: red[500] }} />}
                  </ListItemAvatar>
                  <Grid container spacing={2}>
                    <Grid container item xs={6} sm={6} md={6} lg={6} spacing={3}>
                      <ListItemText primary="Accredited by" />
                    </Grid>
                    <Grid container item xs={6} sm={6} md={6} lg={6} spacing={3}>
                      <ListItemText secondary={!nameLoading ? <a href={"https://www.theverifynetwork.com"} target={"_blank"}> <img height={20} src={image}></img>
                      </a> : " ..."} />
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider variant="inset" component="li" /> */}
                  <ListItem className={classes.item}>
                    <ListItemAvatar>
                      {nameLoading && (
                        <CircularProgress size={24} thickness={4} />
                      )}
                      {!nameLoading && checkNameSuccess && (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                      )}
                      {!nameLoading && checkNameError && (
                        <HighlightOffIcon style={{ color: red[500] }} />
                      )}
                      {/* <Avatar>
                  <BeachAccessIcon />
                </Avatar> */}
                    </ListItemAvatar>
                    {/* <ListItemText primary="Issued through" secondary={!nameLoading ? <>
                <img height={20} src={"https://www.niftron.com/images/NIFTRON%20LOGO.png"}></img>
                {" "}{makePresentable("Niftron")} 
              </> : " ..."} /> */}

                    <Grid container spacing={2}>
                      <Grid
                        container
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        spacing={3}
                      >
                        <ListItemText primary='Issued through' />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        spacing={3}
                      >
                        <ListItemText
                          secondary={
                            !nameLoading ? (
                              <a
                                href={'https://www.niftron.com'}
                                target={'_blank'}
                              >
                                <img
                                  height={20}
                                  src={
                                    'https://www.niftron.com/images/NIFTRON%20LOGO.png'
                                  }
                                ></img>
                                {/* {" "}{makePresentable("Niftron")}  */}
                              </a>
                            ) : (
                              ' ...'
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider variant='inset' component='li' />
                  <ListItem className={classes.item}>
                    <Grid container justify='center'>
                      <Grid item>
                        {!nameLoading && checkNameSuccess && (
                          <h3 style={{ color: green[500] }}>VERIFIED!</h3>
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                  {/* <Grid container justify="center"> */}
                  {/* <Grid item> */}
                  {/* <ListItemAvatar> */}
                  {/* {nameLoading && <CircularProgress
                  size={32}
                  thickness={4} />} */}
                  {/* {!nameLoading && checkNameSuccess && <CheckCircleOutlineIcon fontSize={"large"} style={{ color: green[500] }} />}
              {!nameLoading && checkNameError && <HighlightOffIcon fontSize={"large"} style={{ color: red[500] }} />} */}
                  {/* </ListItemAvatar> */}
                  {/* </Grid> */}
                  {/* </Grid> */}
                </List>
              </DialogContent>
            </Dialog>
          )}
          {transfer && (
            <Grid container justify='center' spacing={2}>
              <Grid item>
                <Button
                  color={'success'}
                  width='50'
                  disabled={!showVerification}
                  onClick={e => {
                    e.preventDefault()
                    handleOpen()
                    setNameLoading(true)
                    setTimeout(function () {
                      setNameLoading(false)

                      setCheckNameSuccess(true)
                    }, 3000)
                  }}
                >
                  Verify
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color={'info'}
                  width='50'
                  disabled={!showVerification}
                  target='_blank'
                  href={prepareStellarTransactionLink(transfer.txnHash)}
                >
                  Verify in Blockchain
                </Button>
              </Grid>
            </Grid>
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <br />
          <br />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className={classes1.container}>
            <AppBar position='static' color='default'>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor='primary'
                textColor='primary'
                variant='scrollable'
                scrollButtons='auto'
                aria-label='full width tabs example'
              >
                <Tab label='Details' />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <Card>
                  <CardBody>
                    <Grid container spacing={1}>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        spacing={3}
                      >
                        <ListItem>
                          <ListItemText
                            primary={
                              makePresentable(props.item.category) + ' Name'
                            }
                          />
                        </ListItem>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={8}
                        lg={9}
                        spacing={3}
                      >
                        <ListItem>
                          <ListItemText secondary={props.item.tokenName} />
                        </ListItem>
                      </Grid>
                      <Divider variant='middle' />
                      <Grid
                        container
                        item
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        spacing={3}
                      >
                        <ListItem>
                          <ListItemText primary='Issued By' />
                        </ListItem>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={8}
                        lg={9}
                        spacing={3}
                      >
                        <ListItem>
                          <ListItemText secondary={props.item.assetIssuer} />
                        </ListItem>
                      </Grid>
                      <Divider variant='middle' />
                      {/* <Grid container item item xs={12} sm={6} md={4} lg={3} spacing={3}>
                        <ListItem >
                          <ListItemText primary="Accredited By" />
                        </ListItem>
                      </Grid>
                      <Grid container item xs={12} sm={6} md={8} lg={9} spacing={3}>
                        <ListItem >
                          <ListItemText secondary={"Verify"} />
                        </ListItem>
                      </Grid>
                      <Divider variant="middle" /> */}
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        spacing={3}
                      >
                        <ListItem>
                          <ListItemText primary='Niftron ID' />
                        </ListItem>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={8}
                        lg={9}
                        spacing={3}
                      >
                        <ListItem>
                          <ListItemText secondary={props.item.assetCode} />
                        </ListItem>
                      </Grid>
                      <Divider variant='middle' />

                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        spacing={3}
                      >
                        <ListItem>
                          <ListItemText primary='Transaction Hash' />
                        </ListItem>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={8}
                        lg={9}
                        spacing={3}
                      >
                        <ListItem>
                          <ListItemText
                            secondary={xdrToTxnHash(props.item.xdr)}
                          />
                        </ListItem>
                      </Grid>
                      <Divider variant='middle' />
                      {ipfsData &&
                        ipfsDataArray.length > 0 &&
                        ipfsDataArray.map((item, i) => (
                          <>
                            <Grid
                              container
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              lg={3}
                              spacing={3}
                            >
                              <ListItem>
                                <ListItemText
                                  primary={makePresentable(item.key)}
                                />
                              </ListItem>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              sm={6}
                              md={8}
                              lg={9}
                              spacing={3}
                            >
                              <ListItem>
                                <ListItemText secondary={item.value} />
                              </ListItem>
                            </Grid>
                            <Divider variant='middle' />
                          </>
                        ))}
                      {ipfsTransferData &&
                        ipfsTransferDataArray.length > 0 &&
                        ipfsTransferDataArray.map((item, i) => (
                          <>
                            <Grid
                              container
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              lg={3}
                              spacing={3}
                            >
                              <ListItem>
                                <ListItemText
                                  primary={makePresentable(item.key)}
                                />
                              </ListItem>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              sm={6}
                              md={8}
                              lg={9}
                              spacing={3}
                            >
                              <ListItem>
                                <ListItemText secondary={item.value} />
                              </ListItem>
                            </Grid>
                            <Divider variant='middle' />
                          </>
                        ))}
                      {!ipfsData ||
                        (!ipfsTransferData && (
                          <Grid
                            container
                            item
                            xs={6}
                            sm={6}
                            md={4}
                            lg={3}
                            spacing={3}
                          >
                            <ListItem>
                              <ListItemText primary={' . . . '} />
                            </ListItem>
                          </Grid>
                        ))}
                    </Grid>
                  </CardBody>
                </Card>
              </TabPanel>
            </SwipeableViews>
          </div>
        </GridItem>
      </GridContainer>
    </>
  )
}
