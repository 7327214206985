import React from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
// import Art from "views/Explorer/Components/Art"
// import Ticket from "views/Explorer/Components/Ticket"
import Certificate from "./Certificate"
import Badge from "./Badge"
import NiftronIdentity from "./Identity"
import Token from "./Token"

const useStyles = makeStyles(styles);

export default function TokenMorpher(props) {
    const classes = useStyles();
    return (
        <div>
            {/* {props.item.category === "DIGITALART" &&
                <Art item={props.item}
                />} */}

            {props.item.category === "CERTIFICATE" &&
                <Certificate item={props.item} issuer={props.issuer} network={props.network}
                />}
            {props.item.category === "BADGE" &&
                <Badge item={props.item} issuer={props.issuer} network={props.network}
                />}
                 {props.item.category === "TOKEN" &&
                <Token item={props.item} issuer={props.issuer} network={props.network}
                />}
            {props.item.category === "IDENTITY" && props.item.assetIssuer === "GCVLWE2L25EGBD5HIDZQY6G32CIBEGQUGP4AM64NVKNWOZLQKOJJH723" &&
                <NiftronIdentity item={props.item} issuer={props.issuer} network={props.network}
                />}
            {/* {props.item.category === "TICKET" &&
                <Ticket
                    item={props.item}
                    eventName={props.item.eventName}
                    edition={props.item.edition}
                    image={props.item.previewUrl}
                    eventDate={props.item.eventDate}
                    location={props.item.location}
                    price={props.item.price}
                    niftronId={props.item._id}
                    createdAt={props.item.createdAt}
                />} */}

        </div>




    );
}
