import React from "react";
import DateRange from "@material-ui/icons/DateRange";

import { makeStyles } from "@material-ui/core/styles";
import { CardMedia } from '@material-ui/core';

// core ../../components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardToken from "../../components/Card/CardToken.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";
const useStyles = makeStyles(styles);

export default function DummyToken(props) {
    const classes = useStyles();
    return (
        

        <GridContainer justify="center" style={{ minHeight: 500 }}>
            <GridItem xs={12} sm={12} md={8} lg={8} className={"loadingCard"}>
                <Card className={"loadingCard"}>
                    {/* <CardToken color="grey" className={"loadingCard"}> */}
                        <div className="container loadingCard">
                            <CardMedia style={{ height: 500, maxHeight: 500 ,width:900}} image={""} />
                            <div class="overlayLoading">
                                <div class="textBlack">
                                    <div className="sweet-loading">
                                        <PulseLoader
                                            color={"#008CBA"}
                                            loading={true}
                                        />
                                    </div>
                                    {"Loading Preview"}</div>
                            </div>
                        </div>
                    {/* </CardToken> */}
                </Card>
            </GridItem>
            <br/><br/>

            <GridItem xs={12} sm={12} md={12} lg={12} className={"loadingCard"}>
                <Card className={"loadingCard"}>
                    <CardBody className={"loadingCard"}>
                        <div className="container loadingCard">
                            <div style={{ height: 345, maxHeight: 345 }} ></div>
                            <div class="overlayLoading">
                                <div class="textBlack">
                                    <div className="sweet-loading">
                                        <PulseLoader
                                            color={"#008CBA"}
                                            loading={true}
                                        />
                                    </div>{props.loadingText}</div>
                            </div>
                        </div>
                    </CardBody>
                    <CardFooter stats>
                    </CardFooter>
                </Card>
            </GridItem>
        </GridContainer>
    
    );
}
