import React, { useState, useEffect } from "react";
import { CardMedia } from "@material-ui/core";
import DateRange from "@material-ui/icons/DateRange";
import { format } from "timeago.js";

// core ../../components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardToken from "../../components/Card/CardToken.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import Button from "../../components/CustomButtons/Button";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import axios from "axios";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import People from "@material-ui/icons/People";

import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import Divider from "@material-ui/core/Divider";

import { green, red } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import image from "../../assets/img/Verify.png";
import styles from "../../assets/jss/material-kit-react/views/loginPage.js";

import { getTransferByNidAndReceiver } from "../../services/TransferManagement";

const useStyles1 = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
  root2: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,
  },
  paperName: {
    padding: theme.spacing(1),
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
  paperValue: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  item: {
    minWidth: 360,
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function Identity(props) {
  const classes = useStyles();
  const classes1 = useStyles1();

  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [showVerification, setShowVerification] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);

  const [xdr, setXdr] = React.useState("");
  const [transaction, setTranaction] = React.useState(null);

  const [loading, setLoading] = useState(true);
  const [nameLoading, setNameLoading] = useState(false);
  const [checkNameError, setCheckNameError] = useState(false);
  const [checkNameSuccess, setCheckNameSuccess] = useState(false);

  document.body.style.overflow = "scroll";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleOpen = () => {
    setOpenDialog(true);
  };

  const makePresentable = (text) => {
    return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
  };

  const makePkPresentable = (text) => {
    return `${text.substring(0, 10)} ...`;
  };
  const prepareStellarTransactionLink = (txnHash) => {
    const values = btoa(JSON.stringify({ transaction: txnHash }));
    //console.log(values);
    const stellarLaboratory =
      "https://laboratory.stellar.org/#explorer?resource=operations&endpoint=for_transaction&";
    const network = "public";
    return `${stellarLaboratory}values=${values}&network=${network}`;
  };
  const prepareStellarXDRLink = (xdr) => {
    const values = encodeURIComponent(xdr);
    //console.log(values);
    const stellarLaboratory = "https://laboratory.stellar.org/#xdr-viewer?";
    const network = props.network;
    return `${stellarLaboratory}input=${values}&type=TransactionEnvelope&network=${network}`;
  };
  const prepareStellarAccountLink = (publicKey) => {
    const values = btoa(JSON.stringify({ account_id: publicKey }));
    //console.log(values);
    const stellarLaboratory =
      "https://laboratory.stellar.org/#explorer?resource=accounts&endpoint=single&";
    const network = props.network;
    return `${stellarLaboratory}values=${values}&network=${network}`;
  };

  useEffect(() => {
    async function fetchStellarXDRData() {
      try {
        const res = await axios.get(
          `https://horizon.stellar.org/transactions/${props.item.txnHash}`
        );
        if (res) {
          setXdr(res.data.envelope_xdr);
        }
      } catch (er) {
        //console.log(er);
      }
    }
    async function fetchStellarTransactionData() {
      try {
        const res = await axios.get(
          `https://horizon.stellar.org/transactions/${props.item.txnHash}/operations`
        );
        if (res) {
          setTranaction(res.data._embedded.records);
        }
      } catch (er) {
        //console.log(er);
      }
    }

    fetchStellarXDRData();
    fetchStellarTransactionData();
  }, []);
  return (
    // <div className={classes1.container} >

    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={8} lg={8}>
        <Card>
          <CardMedia
            style={{
              height: 500,
              maxHeight: 500,
              "background-size": "contain",
            }}
            image={props.item.previewUrl}
          />
        </Card>
        <Grid container justify="center" spacing={2}>
          {/* <Grid item>
              <Button color={"success"} width="50" disabled={!showVerification} onClick={(e) => {
                e.preventDefault()
                handleOpen()
                setNameLoading(true)
                setTimeout(function () {
                  setNameLoading(false)

                  setCheckNameSuccess(true)
                }, 3000);

              }} >Verify</Button>
            </Grid> */}
          {xdr != "" && (
            <Grid item>
              <Button
                color={"info"}
                width="50"
                disabled={!showVerification}
                target="_blank"
                href={prepareStellarXDRLink(xdr)}
              >
                Verify in Blockchain
              </Button>
            </Grid>
          )}
        </Grid>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <br />
        <br />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <div className={classes1.container}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="full width tabs example"
            >
              <Tab label="Details" />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Card>
                <CardBody>
                  <Grid container spacing={1}>
                    {transaction &&
                      transaction.length > 0 &&
                      transaction.map((item, i) => {
                        if (
                          item.type === "manage_data" &&
                          item.name !== "MERCHANTID" &&
                          item.name.trim(" ") !== "TYPE"
                        ) {
                          return (
                            <>
                              <Grid
                                container
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                spacing={3}
                              >
                                <ListItem>
                                  <ListItemText
                                    primary={makePresentable(item.name)}
                                  />
                                </ListItem>
                              </Grid>
                              <Grid
                                container
                                item
                                xs={12}
                                sm={6}
                                md={8}
                                lg={9}
                                spacing={3}
                              >
                                <ListItem>
                                  <ListItemText secondary={atob(item.value)} />
                                </ListItem>
                              </Grid>
                              <Divider variant="middle" />
                            </>
                          );
                        }
                      })}
                    <Grid
                      container
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      spacing={3}
                    >
                      <ListItem>
                        <ListItemText primary="Niftron ID" />
                      </ListItem>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={6}
                      md={8}
                      lg={9}
                      spacing={3}
                    >
                      <ListItem>
                        <ListItemText secondary={props.item.assetCode} />
                      </ListItem>
                    </Grid>
                    <Divider variant="middle" />

                    <Grid
                      container
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      spacing={3}
                    >
                      <ListItem>
                        <ListItemText primary="Transaction Hash" />
                      </ListItem>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={6}
                      md={8}
                      lg={9}
                      spacing={3}
                    >
                      <ListItem>
                        <ListItemText
                          secondary={
                            <a
                              target="_blank"
                              href={prepareStellarTransactionLink(
                                props.item.txnHash
                              )}
                            >
                              {props.item.txnHash}
                            </a>
                          }
                        />
                      </ListItem>
                    </Grid>
                    <Divider variant="middle" />
                  </Grid>
                </CardBody>
              </Card>
            </TabPanel>
          </SwipeableViews>
        </div>
      </GridItem>
    </GridContainer>
    // </div>
  );
}
