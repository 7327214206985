import React, { useEffect, useState } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../components/Grid/GridItem.js";
import GridContainer from "../components/Grid/GridContainer.js";
import TokenMorpher from "../views/Components/TokenMorpher.js";
// import styles from "../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import styles from "../assets/jss/material-kit-react/views/loginPage.js";
import IconButton from "@material-ui/core/IconButton";

import {
  getTokenById,
  getTokenBalanceFromStellar,
} from "../services/TokenManagement";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Slide from "@material-ui/core/Slide";
import DummyToken from "../views/Components/DummyToken";
import "../assets/css/custom.css";

import image from "../assets/img/HeaderVerify.png";
import axios from "axios";

import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Footer from "../components/Footer/Footer";

const useStyles = makeStyles(styles);
function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function Explorer(props) {
  //console.log(props);
  const classes = useStyles();
  const { id, owner } = props;
  const [token, setToken] = useState(null);
  const [loading, setloading] = useState(false);
  const [loadingText, setLoadingText] = useState("loading");
  const [placeholder, setPlaceholder] = useState([0]);
  const [network, setNetwork] = React.useState("public");

  useEffect(() => {
    const fetchData = async () => {
      setloading(true);
      const balances = await getTokenBalanceFromStellar(owner);
      let result = await getTokenById(id);
      if (result != null) {
        if (result.data) {
          if (balances != null) {
            balances.forEach((sToken) => {
              if (sToken.assetCode == result.data.assetCode) {
                result.data.assetCount = sToken.balance * 10000000;
              }
            });
          }
          setToken(result.data);
        }
        setloading(false);
      }
    };
    const fetchNetwork = async () => {
      try {
        const res = await axios.get(
          `https://horizon.stellar.org/accounts/${owner}`
        );
        if (res) {
          console.log(res);
          if (res.status == 404) {
            setNetwork("test");
          }
        }
      } catch (er) {
        setNetwork("test");

        // console.log(er);
      }
    };
    fetchNetwork();

    fetchData();
  }, []);

  return (
    <div>
      <HideOnScroll {...props}>
        <AppBar color="white">
          <Toolbar>
            <Grid
              justify="space-between" // Add it here :)
              container
              spacing={24}
            >
              <Grid item xs={6}>
                <a href={"/"}>
                  <img
                    style={{ maxHeight: 30 }}
                    src="https://www.niftron.com/images/NIFTRON%20LOGO.png"
                  />
                </a>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <br />
      <br />
      <br />
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {loading && <DummyToken loadingText={loadingText} />}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {!loading && token && (
            <TokenMorpher item={token} owner={owner} network={network} />
          )}
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}
