import React, { useEffect, useState } from 'react'
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridItem from '../components/Grid/GridItem.js'
import GridContainer from '../components/Grid/GridContainer.js'
import Token from './Components/Token'
// import styles from "../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import styles from '../assets/jss/material-kit-react/views/loginPage.js'
import IconButton from '@material-ui/core/IconButton'

import {
  getTokenById,
  getTokenBalanceFromStellar
} from '../services/TokenManagement'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CssBaseline from '@material-ui/core/CssBaseline'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Slide from '@material-ui/core/Slide'
import DummyToken from './Components/DummyToken'
import '../assets/css/custom.css'

import image from '../assets/img/HeaderVerify.png'
import axios from 'axios'

import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Footer from '../components/Footer/Footer'
const Web3 = require('web3')


const web3Providers = {
  providerBscTestNet: new Web3.providers.HttpProvider(
    "https://data-seed-prebsc-1-s1.binance.org:8545/"
  ),
  providerRinkeby: new Web3.providers.HttpProvider(
    "https://rinkeby.infura.io/v3/1ae5799b9f6c4321951ad280f2b82a0f"
  ),
  providerBsc: new Web3.providers.HttpProvider(
    "https://bsc-dataseed1.ninicoin.io"
  ),
  providerEthereum: new Web3.providers.HttpProvider(
    "https://mainnet.infura.io/v3/1ae5799b9f6c4321951ad280f2b82a0f"
  ),
  providerMatic: new Web3.providers.HttpProvider(
    "https://rpc-mainnet.maticvigil.com/v1/0e23e37bcd301b8ff7c20ea76b22bd763f70e3ba"
  ),
  providerMumbai: new Web3.providers.HttpProvider(
    "https://rpc-mumbai.maticvigil.com/v1/0e23e37bcd301b8ff7c20ea76b22bd763f70e3ba"
  )
};
const ERC721ABI = require("./ERC721.json");

let ethWeb3 = new Web3(web3Providers.providerEthereum)
let rinkebyWeb3 = new Web3(web3Providers.providerBscTestNet)
let bscWeb3 = new Web3(web3Providers.providerBsc)
let bscTestnetWeb3 = new Web3(web3Providers.providerBscTestNet)
let maticWeb3 = new Web3(web3Providers.providerMatic)
let mumbaiWeb3 = new Web3(web3Providers.providerMumbai)

const useStyles = makeStyles(styles)
function HideOnScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({ target: window ? window() : undefined })

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
}

export default function Explorer3(props) {
  const { contractID, tokenID, chainID } = props
  const [token, setToken] = useState(null)
  const [owner, setOwner] = useState(null)

  const [loading, setloading] = useState(false)
  const [loadingText, setLoadingText] = useState('loading')
  const [web3, setWeb3] = useState(new Web3(web3Providers.providerMatic))
  useEffect(() => {
    const fetchData = async () => {
      setloading(true);
      switch (chainID) {
        case "1":

          const ethContract = new ethWeb3.eth.Contract(
            ERC721ABI,
            contractID
          );

          const ethOwner = await ethContract.methods
            .ownerOf(tokenID)
            .call({ from: "0x923167dEa445A419DC45bCcCeEA0eA708d1313AC" });
          setOwner(ethOwner);

          const ethTokenURI = await ethContract.methods
            .tokenURI(tokenID)
            .call({ from: "0x923167dEa445A419DC45bCcCeEA0eA708d1313AC" });


          const ethRes = await axios.get(ethTokenURI);
          if (ethRes) {
            console.log(ethRes.data);
            setToken(ethRes.data);
          }
          setloading(false)

            ; break;
        case "4":


          const rinkebyContract = new rinkebyWeb3.eth.Contract(
            ERC721ABI,
            contractID
          );

          const rinkebyOwner = await rinkebyContract.methods
            .ownerOf(tokenID)
            .call({ from: "0x923167dEa445A419DC45bCcCeEA0eA708d1313AC" });
          setOwner(rinkebyOwner);

          const rinkebyTokenURI = await rinkebyContract.methods
            .tokenURI(tokenID)
            .call({ from: "0x923167dEa445A419DC45bCcCeEA0eA708d1313AC" });


          const rinkebyRes = await axios.get(rinkebyTokenURI);
          if (rinkebyRes) {
            console.log(rinkebyRes.data);
            setToken(rinkebyRes.data);
          }
          setloading(false)


            ; break;
        case "56":


          const bscContract = new bscContract.eth.Contract(
            ERC721ABI,
            contractID
          );

          const bscOwner = await bscContract.methods
            .ownerOf(tokenID)
            .call({ from: "0x923167dEa445A419DC45bCcCeEA0eA708d1313AC" });
          setOwner(bscOwner);

          const bscTokenURI = await bscContract.methods
            .tokenURI(tokenID)
            .call({ from: "0x923167dEa445A419DC45bCcCeEA0eA708d1313AC" });


          const bscRes = await axios.get(bscTokenURI);
          if (bscRes) {
            console.log(bscRes.data);
            setToken(bscRes.data);
          }
          setloading(false)

            ; break;
        case "97":


          const bscTestnetContract = new bscTestnetWeb3.eth.Contract(
            ERC721ABI,
            contractID
          );

          const bscTestnetOwner = await bscTestnetContract.methods
            .ownerOf(tokenID)
            .call({ from: "0x923167dEa445A419DC45bCcCeEA0eA708d1313AC" });
          setOwner(bscTestnetOwner);

          const bscTestnetTokenURI = await bscTestnetContract.methods
            .tokenURI(tokenID)
            .call({ from: "0x923167dEa445A419DC45bCcCeEA0eA708d1313AC" });


          const bscTestnetRes = await axios.get(bscTestnetTokenURI);
          if (bscTestnetRes) {
            console.log(bscTestnetRes.data);
            setToken(bscTestnetRes.data);
          }
          setloading(false)

            ; break;
        case "137":

          const maticContract = new maticWeb3.eth.Contract(
            ERC721ABI,
            contractID
          );

          const maticOwner = await maticContract.methods
            .ownerOf(tokenID)
            .call({ from: "0x923167dEa445A419DC45bCcCeEA0eA708d1313AC" });
          setOwner(maticOwner);

          const maticTokenURI = await maticContract.methods
            .tokenURI(tokenID)
            .call({ from: "0x923167dEa445A419DC45bCcCeEA0eA708d1313AC" });


          const maticRes = await axios.get(maticTokenURI);
          if (maticRes) {
            console.log(maticRes.data);
            setToken(maticRes.data);
          }
          setloading(false)


            ; break;
        case "80001":

          const mumbaiContract = new mumbaiWeb3.eth.Contract(
            ERC721ABI,
            contractID
          );

          const mumbaiOwner = await mumbaiContract.methods
            .ownerOf(tokenID)
            .call({ from: "0x923167dEa445A419DC45bCcCeEA0eA708d1313AC" });
          setOwner(mumbaiOwner);

          const mumbaiTokenURI = await mumbaiContract.methods
            .tokenURI(tokenID)
            .call({ from: "0x923167dEa445A419DC45bCcCeEA0eA708d1313AC" });


          const mumbaiRes = await axios.get(mumbaiTokenURI);
          if (mumbaiRes) {
            console.log(mumbaiRes.data);
            setToken(mumbaiRes.data);
          }
          setloading(false)


            ; break;
        default:

          const contract = new mumbaiWeb3.eth.Contract(
            ERC721ABI,
            contractID
          );

          const owner = await contract.methods
            .ownerOf(tokenID)
            .call({ from: "0x923167dEa445A419DC45bCcCeEA0eA708d1313AC" });
          setOwner(owner);

          const tokenURI = await mumbaiContract.methods
            .tokenURI(tokenID)
            .call({ from: "0x923167dEa445A419DC45bCcCeEA0eA708d1313AC" });


          const res = await axios.get(tokenURI);
          if (res) {
            console.log(res.data);
            setToken(res.data);
          }
          setloading(false)
            ;
      }
    }
    fetchData()
  }, [])

  return (
    <div>
      <HideOnScroll {...props}>
        <AppBar color='white'>
          <Toolbar>
            <Grid
              justify='space-between' // Add it here :)
              container
              spacing={24}
            >
              <Grid item xs={6}>
                <a href={'/'}>
                  <img
                    style={{ maxHeight: 30 }}
                    src='https://www.niftron.com/images/NIFTRON%20LOGO.png'
                  />
                </a>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <br />
      <br />
      <br />
      <Grid
        container
        direction='column'
        justify='space-around'
        alignItems='center'
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {loading && <DummyToken loadingText={loadingText} />}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {!loading && token && (
            <Token item={token} contractID={contractID} tokenID={tokenID} owner={owner} chainID={chainID} />
          )}
        </Grid>
      </Grid>
      <Footer />
    </div>
  )
}
